/* eslint-disable */

<template>
  <div class="landing-page">
    <div class="box">
      <div
        :class="[
          'content',
          'left',
          applyAnimation && 'animate__animated animate__fadeInLeftBig',
        ]"
      >
        <img class="icon" :src="require('./assets/icon-amoofertas.png')" />
        <img class="logo" :src="require('./assets/logo-amoofertas.png')" />
        <div class="buttons">
          <button @click="goToPlayStore()">
            <i class="mdi mdi-google-play"></i>
            <span>{{ $t("play-store") }}</span>
          </button>
          <button @click="goToAppStore()">
            <i class="mdi mdi-apple"></i>
            <span>{{ $t("app-store") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="box flex-start">
      <div class="content">
        <img
          :class="[
            applyAnimation && 'animate__animated animate__fadeInRightBig',
          ]"
          :src="require('./assets/phone-amoofertas.png')"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  created() {
    if (!this.redirected) {
      this.redirect();
      this.redirected = true;
    }
  },
  computed: {
    applyAnimation() {
      return window.innerWidth > 860;
    },
  },
  methods: {
    getFullUrl() {
        return window.location.href;
    },
    goToPlayStore() {
      window.location.href = 'https://play.google.com/store/apps/details?id=delivery.amo.ofertas'
    },
    goToAppStore() {
      window.location.href = 'https://apps.apple.com/br/app/amo-ofertas/id1420192544'
    },
    redirect() {
      let deepLink = `amoofertasapp://${this.getFullUrl().replaceAll('https://amoofertas.com/', '')||''}`;


      function preventPopup() {
        window.removeEventListener('blur', preventPopup);
      }

      function go() {
          window.location.href = deepLink;
          window.addEventListener('blur', preventPopup);
      }

      go();
    }
  }
};
</script>

<style lang="scss">
@import "./style.scss";
</style>