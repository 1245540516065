import { createI18n } from 'vue-i18n';
import ptBr from '../languages/pt-br';
import enUs from '../languages/en-us';

const locale = () => {
    const nl = navigator.language;
    if (["pt-BR", "pt"].includes(nl)) return 'pt-BR';
    if (["en-US", "en"].includes(nl)) return 'en';
    return 'pt-BR';
};

const i18n = createI18n({
    legacy: false,
    locale: locale(),
    messages: {
        'pt-BR': ptBr,
        'en': enUs
    }
});

export default i18n;
